module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"defaultLayouts":{"default":"/home/runner/work/bmsshuin/bmsshuin/src/components/layout.jsx"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"bmsshuin","short_name":"bmsshuin","start_url":"/","icon":"static/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"b363814c90ddeb0bdd83e11ac0658c41"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
