import { Link } from "gatsby";
import Layout from "../../../../src/components/3rd/layout";
import SEO from "../../../../src/components/seo";
import Wrapper from "../../../../src/components/wrapper";
import styles from "../../../../src/pages/1st/index.module.scss";
import * as React from 'react';
export default {
  Link,
  Layout,
  SEO,
  Wrapper,
  styles,
  React
};